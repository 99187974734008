<template>
  <div class="select-card">
    <div class="select-card-top">
      <div class="title">收入概况</div>
      <div class="check_con">
        <el-checkbox v-model="isTaxCheck">切换到含税口径</el-checkbox>
      </div>
    </div>
    <div class="select-card-bottom">
      <div class="select-card-bottom-left">
        <!-- 数据维度 -->
        <el-cascader
          collapse-tags
          v-model="select_data_tree"
          :options="data_tree"
          :props="{
            multiple: true,
            label: 'name',
            value: 'code',
          }"
          placeholder="请选择组织架构"
          size="small"
          style="min-width: 160px"
          ref="myCascader"
          filterable
          show-all-levels
          @change="handleChange"
        >
          <template #default="{ data }">
            <span>{{ data.name }}</span>
            <span v-if="data.delFlag!=0">(旧)</span>
          </template>
        </el-cascader>
        <div style=" margin-left: 10px;">
          <el-select v-model="personnelIdentity" placeholder="请选择身份" size="small" clearable multiple>
            <el-option
              v-for="item,key in personnelIdentityArr"
              :key="item"
              :label="item"
              :value="key"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 产品线 -->
        <!-- <el-cascader
          collapse-tags
          v-model="selectClass"
          :options="productClass"
          :props="{ multiple: true, label: 'name', value: 'typeId' }"
          placeholder="请选择产品线"
          size="small"
          style="min-width: 160px; margin-left: 12px"
          filterable
        >
          <template #default="{ data }">
            <span>{{ data.name }}</span>
          </template>
        </el-cascader> -->
        <el-select v-model="selectClass" placeholder="请选择产品线" size="small" clearable multiple>
            <el-option
              v-for="item in productClass"
              :key="item.typeId"
              :label="item.name"
              :value="item.typeId"
            >
            </el-option>
          </el-select>
        <el-cascader
          collapse-tags
          v-model="doctorIds"
          :options="doctors"
          :props="cascaderProps"
          placeholder="请选择客户"
          size="small"
          style="min-width: 120px; margin-left: 12px"
        >
          <template #default="{ data }">
            <span>{{ data.regionName }}</span>
          </template>
        </el-cascader>
      </div>
    </div>
    
    <div class="select-card-bottom" style="align-items:flex-start;">
        <el-select
          v-model="timeType"
          placeholder="请选择"
          size="small"
          style="width: 100px;"
        >
          <el-option
            v-for="item in dateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div v-show="timeType == 1">
          <el-date-picker
            class="select-day"
            style="margin-left: 24px; margin-right: 20px"
            format="yyyy-MM-dd"
            v-model="timeDay"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions1"
            size="small"
          >
          </el-date-picker>
        </div>
        <div v-show="timeType == 2" class="week-box">
          <el-date-picker
            class="week-input1"
            style="margin-left: 20px; margin-right: 20px"
            v-model="timeWeekStart"
            type="week"
            :format="`yyyy 第 WW 周`"
            placeholder="选择开始周"
            size="small"
            :picker-options="pickerOptions2"
          >
          </el-date-picker>
          <el-date-picker
            class="week-input2"
            style="margin-right: 20px"
            v-model="timeWeekEnd"
            type="week"
            :format="`yyyy 第 WW 周`"
            placeholder="选择结束周"
            size="small"
            :picker-options="pickerOptions2"
          >
          </el-date-picker>
        </div>
        <div v-show="timeType == 3">
          <el-date-picker
            class="select-day"
            style="margin-left: 20px; margin-right: 20px"
            format="yyyy-MM"
            v-model="timeMonth"
            type="monthrange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            :picker-options="pickerOptions3"
            size="small"
          >
          </el-date-picker>
        </div>
        <el-button
          type="primary"
          size="small"
          @click="querySelect"
          :loading="isLoading"
          >查询</el-button
        >
      </div>
  </div>
</template>

<script>
import Util from "@/utils/getMon";
export default {
  computed: {
    currentDate() {
      return this.currentDay ? this.currentDay : new Date();
    },
  },
  data() {
    const _this = this;
    return {
      isTax: 2,
      downType: "",
      isTaxCheck: false,
      testValue: [],
      cascaderProps: {
        multiple: true,
        label: "regionName",
        value: "regionCode",
        lazy: true,
        checkStrictly: true,
        lazyLoad: this.lazyLoad,
      },
      downOptions: [
        {
          value: 1,
          label: "大区目标",
        },
        {
          value: 2,
          label: "人的目标",
        },
        {
          value: 3,
          label: "产品线目标",
        },
      ],
      dateOptions: [
        {
          value: 1,
          label: "日",
        },
        {
          value: 2,
          label: "周",
        },
        {
          value: 3,
          label: "月",
        },
      ],
      // 日
      pickerOptions1: {
        disabledDate(date) {
          return date.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date() - 60 * 60 * 24 * 1000;
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date() - 60 * 60 * 24 * 1000;
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 周
      pickerOptions2: {
        firstDayOfWeek: 1,
        disabledDate(date) {
          let day = Date.now();
          let limit = Util.getMon(day);
          let limitTime = new Date(limit);
          return date.getTime() > limitTime.getTime() +8.64e7;
          // return date.getTime() == Date.now();
        },
      },
      // 月
      pickerOptions3: {
        disabledDate(date) {
          let t = new Date().getDate();
          return date.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今年至今",
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近六个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      select_data_tree: "", // 选中的数据维度权限
      data_tree: [], // 数据维度树
      productClass: [], // 产品类目列表
      selectClass: [], // 选中的类目
      timeType: 1, // 日期类型
      timeDay: "", // 选中日
      timeWeekStart: "", // 选中周
      timeWeekEnd: "",
      timeMonth: "", // 选中月
      startTime: "", // 开始时间
      endTime: "", // 结束时间
      week1: "", // 本月第几周
      week2: "", // 本月第几周
      isLoading: false,
      unSelectDept: [], //未选中的架构
      levelArr: [], //
      doctors: [],
      doctorIds: [],
      personnelIdentity:"",
      personnelIdentityArr:[]
    };
  },
  created() {
    // this.handleChange()
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
    var tMonth = new Date().getMonth() + 1;
    // console.log("月份", tMonth);
    this.timeDay = [start, end];
    //  获取交易概览选择列表
    this.axios.post("TRANSACTION_LIST", { type: 1 }).then((result) => {
      // console.log("获取交易概览选择列表", result);
      if (result.retCode == "200") {
        this.data_tree =result.data.team;
        this.productClass = result.data.goodsType;
        this.doctors = result.data.doctors;
        this.personnelIdentityArr = result.data.personnelIdentity
      } else {
        this.$message.error(result.msg);
      }
      // 重新组装结构
    });
    // 默认调用一下
    this.querySelect();
  },
  methods: {
    getMon(date) {
      let nowTemp = new Date(date); //当前时间

      let oneDayLong = 24 * 60 * 60 * 1000; //一天的毫秒数

      let c_time = nowTemp.getTime(); //当前时间的毫秒时间

      let c_day = nowTemp.getDay() || 7; //当前时间的星期几

      let m_time = c_time - (c_day - 1) * oneDayLong; //当前周一的毫秒时间

      let monday = new Date(m_time); //设置周一时间对象

      let m_year = monday.getFullYear();

      let m_month = monday.getMonth() + 1;
      m_month = m_month < 10 ? "0" + m_month : m_month;

      let m_date = monday.getDate();
      m_date = m_date < 10 ? "0" + m_date : m_date;

      return m_year + "-" + m_month + "-" + m_date;
    },
    lazyLoad(node, resolve) {
      // console.log("lazyLoad", node);
      this.getData(node, resolve);
    },
    async getData(node, resolve) {
      let that = this;
      let { level, value } = node;
      // console.log(node);
      let params = {
        levelType: parseInt(level + 1),
        regionCode: value,
        type: 1,
      };
      console.log(params);
      if (!node.data) {
        // console.log("hdjshdjshdhjsdhds");
        let test = [];
        await this.axios
          .post("HospitalOrDoctor", params)
          .then((result) => {
            if (result.retCode == "200") {
              // console.log(result.data);
              test = result.data;
              setTimeout(() => {
                const nodes = Array.from(test).map((item) => ({
                  value: item.regionCode,
                  label: `${item.regionName}`,
                  leaf: level >= 4,
                }));
                resolve([...nodes]);
              }, 500);
            } else {
              this.$message.error(result.msg);
            }
          })
          .catch(() => {
            this.isLoading = false;
            this.$store.dispatch("app/setLoading", false);
          });
      } else {
        let test = [];
        await this.axios
          .post("HospitalOrDoctor", params)
          .then((result) => {
            if (result.retCode == "200") {
              test = result.data;
              // console.log(test, "test");
              setTimeout(() => {
                const nodes = Array.from(test).map((item) => {
                  return {
                    regionCode: item.regionCode,
                    regionName: `${item.regionName}`,
                    leaf: level >= 4,
                    // level
                  };
                });
                resolve(nodes);
                console.log(node.children, "eeee");
              }, 500);
            } else {
              this.$message.error(result.msg);
            }
          })
          .catch(() => {
            this.isLoading = false;
            this.$store.dispatch("app/setLoading", false);
          });
      }
    },
    handleChange() {
      this.$nextTick(() => {
        let unselect = [];
        let checkedNodeList = this.$refs["myCascader"].getCheckedNodes();
        // console.log("checkedNodeList", checkedNodeList);
        // console.log(this.select_data_tree, "this.select_data_tree");

        checkedNodeList = checkedNodeList.filter(
          (item) => !(item.parent && item.parent.checked)
        ); // 核心
        this.testValue = checkedNodeList;
        // console.log("checkedNodeList", checkedNodeList);
        // console.log(this.testValue, "this.testValue");
        if (this.select_data_tree.length > 0) {
          this.select_data_tree.forEach((i, index) => {
            this.testValue.forEach((element) => {
              // console.log(element);
              if (element.parent) {
                if (!element.parent.checked) {
                  // console.log(
                  //   element.parent.label,
                  //   element.parent.value
                  // );
                  unselect.push(element.parent.value);
                }
              }
            });
            this.unSelectDept = [...new Set(unselect)];
            
          });
        }
          console.log(this.unSelectDept);
      });
    },
    // 根据日期判断是月的第几周
    getWeekInMonth(t) {
      if (t == undefined || t == "" || t == null) {
        t = new Date();
      } else {
        var _t = new Date();
        _t.setYear(t.getFullYear());
        _t.setMonth(t.getMonth());
        _t.setDate(t.getDate());

        var date = _t.getDate(); //给定的日期是几号

        _t.setDate(1);
        var d = _t.getDay(); //1. 得到当前的1号是星期几。
        var fisrtWeekend = d;
        if (d == 0) {
          fisrtWeekend = 1;
          //1号就是星期天
        } else {
          fisrtWeekend = 7 - d + 1; //第一周的周未是几号
        }
        if (date <= fisrtWeekend) {
          return 1;
        } else {
          return 1 + Math.ceil((date - fisrtWeekend) / 7);
        }
      }
    },
    weekChange1(val) {
      if (val) {
        this.week1 = this.getWeekInMonth(new Date(val));
      }
    },
    weekChange2(val) {
      if (val) {
        this.week2 = this.getWeekInMonth(new Date(val));
      }
    },
    // 切换日期类型
    checkTimeType(index) {
      this.timeType = index;
      this.timeDay = "";
      this.timeWeekStart = "";
      this.timeWeekEnd = "";
      this.timeMonth = "";
      this.startTime = "";
      this.endTime = "";
      // 广播出去
      // Bus.$emit("overviewTimeType", this.timeType);
    },
     unselectArr(){
      let temp = []
      // console.log(this.unSelectDept)
      this.unSelectDept.forEach((u,uindex)=>{
        this.select_data_tree.forEach((i,index)=>{
          
            if(i.indexOf("644774055")>-1||i.indexOf('459098212')>-1){//医院销售部，乳腺
              let flag = i.indexOf(u)
              let i_length = i.length-2
              if(flag>-1&&u==i[i_length]){
               temp.push(i[3])
              }
              if(flag==3){
                 temp.push(u)
              }
            }else{
              let flag = i.indexOf(u)
              let i_length = i.length-2
              
              if(flag>-1&&u==i[i_length]){
              
               temp.push(i[2])
              }
              if(flag==2){
                 temp.push(u)
              }
              
            }

          // }
        })
      })
      this.unSelectDept = [...new Set(temp)]
       return this.unSelectDept
    },

    // 查询数据
    async querySelect() {
      this.unSelectDept = await this.unselectArr()
      if (this.timeType == 1) {
        if (this.timeDay) {
          let startTime = new Date(this.timeDay[0]).getTime();
          let endTime = new Date(this.timeDay[1]).getTime();
          this.startTime = this.formatDate(startTime)
            .replace("-", "")
            .replace("-", "");
          this.endTime = this.formatDate(endTime)
            .replace("-", "")
            .replace("-", "");
        } else {
          this.startTime = "";
          this.endTime = "";
        }
      } else if (this.timeType == 2) {
        if (this.timeWeekStart && this.timeWeekEnd) {
          let moment = require("moment");
          this.startTime = moment(this.timeWeekStart)
            .utcOffset(480)
            .format("yyyyWW");
          let year1 = this.startTime.toString().slice(0, 4);
          let week1 = Number(this.startTime.toString().slice(4));
          this.startTime = year1 + week1;

          this.endTime = moment(this.timeWeekEnd)
            .utcOffset(480)
            .format("yyyyWW");
          let year2 = this.endTime.toString().slice(0, 4);
          let week2 = Number(this.endTime.toString().slice(4));
          this.endTime = year2 + week2;
        } else {
          this.startTime = "";
          this.endTime = "";
        }
      } else if (this.timeType == 3) {
        if (this.timeMonth) {
          // console.log(this.timeMonth);
          // 年
          let year0 = this.timeMonth[0].getFullYear();
          // 月
          let month0 = this.timeMonth[0].getMonth() + 1;
          // 年
          let year1 = this.timeMonth[1].getFullYear();
          // 月
          let month1 = this.timeMonth[1].getMonth() + 1;
          this.startTime = `${year0}${month0 < 10 ? "0" + month0 : month0}`;
          this.endTime = `${year1}${month1 < 10 ? "0" + month1 : month1}`;
        } else {
          this.startTime = "";
          this.endTime = "";
        }
      }
      // console.log("开始时间", this.startTime);
      this.isTax = this.isTaxCheck ? 1 : 2;
      // this.isLoading = true;
      // this.$store.dispatch("app/setLoading", true);
      // 数据请求
      let params = {
        dateType: this.timeType,
        deptCodeStr:
          this.select_data_tree.length > 0
            ? JSON.stringify(this.select_data_tree)
            : "",
        productLineTypes:
          this.selectClass.length > 0 ? JSON.stringify(this.selectClass) : "",
        startTime: this.startTime,
        endTime: this.endTime,
        unSelectDept:
          this.unSelectDept.length > 0 ? JSON.stringify(this.unSelectDept) : "",
        isTax: this.isTax,
        doctorIds:
          this.doctorIds.length > 0 ? JSON.stringify(this.doctorIds) : "",
          personnelIdentity:this.personnelIdentity.length > 0 ? JSON.stringify(this.personnelIdentity) : "",
      };
      this.axios
        .post("SELECT_TRANSACTION", params)
        .then((result) => {
          console.log("查询返回", result);
          if (result.retCode == "200") {
            result.data.timeType = this.timeType;
            this.$store.dispatch("data/setTotalData", result.data);
          } else {
            this.$message.error(result.msg);
          }
          this.$emit("changeData", params);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    formatDate(number) {
      var date = new Date(number);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return YY + MM + DD;
    },
  },
};
</script>

<style>
@media (max-width: 1440px) {
  /* 小于1440 */
  .week-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .week-input2 {
    margin-right: 0 !important;
    margin-top: 10px;
  }
  .text-btn2 {
    margin-left: 8px !important;
  }
  .text-btn3 {
    margin-left: 8px !important;
    margin-right: 0px !important;
  }
  .select-day {
    margin-left: 12px !important;
  }
  .class-list {
    margin-left: 8px !important;
  }
}
</style>

<style scoped lang="scss">
.select-card {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  .btnClass {
    width: 100%;
    height: 28px;
    background: rgba(0, 178, 88, 0.1);
    border-radius: 16px;
    color: #00b258;
    border: none;
    text-align: center;
    span {
      display: inline-block;
      width: 100%;
      line-height: 28px;
      text-align: center;
    }
  }
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
      flex: 1;
    }
    /deep/ .el-checkbox__label {
      display: inline-block;
      padding-left: 10px;
      line-height: 19px;
      font-size: 12px;
    }
    .check_con {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #595959;
      line-height: 17px;
      letter-spacing: 1px;
      margin-right: 24px;
    }
    .btn {
      // margin-right: 12px;
    }
  }
  .select-card-bottom {
    padding: 8px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    .select-card-bottom-left {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .class-list {
        margin-left: 24px;
        width: 200px;
      }
    }
    .select-card-bottom-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>
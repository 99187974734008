<template>
  <div class="context">
    <!-- 交易概览 -->
    <overview @changeData='getMonthData'></overview>
    <!-- 横向滚动卡片组 -->
    <card-grid></card-grid>
    <ChartLine @random='random' />

    <!-- 对比分析 -->
    <Compare  @transfer="getUser"/>
    <Compare1></Compare1>
    <Compare2></Compare2>
    <CompareMap />
  </div>
</template>

<script>
import Overview from "../components/overview.vue";
import CardGrid from "../components/cardgrid.vue";
import ChartLine from "../components/chart_line.vue";
import Compare from "../components/compare";
import Compare1 from "../components/compare1";
import Compare2 from "../components/compare2";
import CompareMap from "../components/compareMap";

import { mapGetters } from "vuex";
export default {
  components: {
    Overview,
    CardGrid,
    ChartLine,
    Compare,
    Compare1,
    Compare2,
    CompareMap,
  },
  computed: {
    ...mapGetters(["cardList"]),
  },
  created() {
    console.log(this.cardList);
    // console.log(this.cardList[0].lineData,"")
  },
  data() {
    return {
      chartTypes: [1, 2, 3, 4],
      random:0,
      testValue:{},
      test_Data: {
        xAxis: [
          "20220324",
          "20220325",
          "20220326",
          "20220327",
          "20220328",
          "20220329",
          "20220330",
        ],
        series: [79.95, 77.11, 33.17, 21.38, 113.33, 89.58, 86.72],
        ratio: [-3.92, -29.27, -68.19, -41.31, 784.51, 13.18, -22.01],
      },
    };
  },
  methods: {
    getMonthData(msg) {
      this.random = Math.random();
    },
    getUser(msg) {
      this.transfer = msg;
      this.random = Math.random();
      console.log(msg,'msg')
    },
  },
};
</script>
<template>
  <div class="select-card">
    <div class="select-card-top">
      <div>对比分析</div>
    </div>
    <div class="select-card-bottom">
      <div class="select-card-bottom-right">
        <el-select v-model="timeType" placeholder="请选择" size="small">
          <el-option
            v-for="item in dateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div v-show="timeType == 1">
          <el-date-picker
            class="select-day"
            style="margin-left: 24px; margin-right: 20px"
            format="yyyy-MM-dd"
            v-model="timeDay"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions1"
            size="small"
          >
          </el-date-picker>
        </div>
        <div v-show="timeType == 2" class="week-box">
          <el-date-picker
            class="week-input1"
            style="margin-left: 20px; margin-right: 20px"
            v-model="timeWeekStart"
            type="week"
            :format="`yyyy 第 WW 周`"
            placeholder="选择开始周"
            size="small"
            :picker-options="pickerOptions2"
          >
          </el-date-picker>
          <el-date-picker
            class="week-input2"
            style="margin-right: 20px"
            v-model="timeWeekEnd"
            type="week"
            :format="`yyyy 第 WW 周`"
            placeholder="选择结束周"
            size="small"
            :picker-options="pickerOptions2"
          >
          </el-date-picker>
        </div>
        <div v-show="timeType == 3">
          <el-date-picker
            class="select-day"
            style="margin-left: 20px; margin-right: 20px"
            format="yyyy-MM"
            v-model="timeMonth"
            type="monthrange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            :picker-options="pickerOptions3"
            size="small"
          >
          </el-date-picker>
        </div>
        <el-button
          type="primary"
          size="small"
          @click="querySelect"
          :loading="isLoading"
          >查询</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
let id = 0;
import Bus from "@/utils/bus";
import Util from "@/utils/getMon";
export default {
  data() {
    return {
      dateOptions: [
        {
          value: 1,
          label: "日",
        },
        {
          value: 2,
          label: "周",
        },
        {
          value: 3,
          label: "月",
        },
      ],
      // 日
      pickerOptions1: {
        disabledDate(date) {
          return date.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date() - 60 * 60 * 24 * 1000;
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date() - 60 * 60 * 24 * 1000;
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date() - 60 * 60 * 24 * 1000;
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 周
      pickerOptions2: {
        firstDayOfWeek: 1,
        disabledDate(date) {
          let day = Date.now();
          let limit = Util.getMon(day);
          let limitTime = new Date(limit);
          return date.getTime() > limitTime.getTime() + 8.64e7;
          // return date.getTime() == Date.now();
        },
      },
      // 月
      pickerOptions3: {
        disabledDate(date) {
          let t = new Date().getDate();
          return date.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今年至今",
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近六个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      select_data_tree: "", // 选中的数据维度权限
      data_tree: [], // 数据维度树
      productClass: [], // 产品类目列表
      selectClass: [], // 选中的类目
      timeType: 1, // 日期类型
      timeDay: "", // 选中日
      timeWeekStart: "", // 选中周
      timeWeekEnd: "",
      timeMonth: "", // 选中月
      startTime: "", // 开始时间
      endTime: "", // 结束时间
      week1: "", // 本月第几周
      week2: "", // 本月第几周
      isLoading: false,
    };
  },
  created() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
    this.timeDay = [start, end];
    this.querySelect();
  },
  methods: {
    // 根据日期判断是月的第几周
    getWeekInMonth(t) {
      if (t == undefined || t == "" || t == null) {
        t = new Date();
      } else {
        var _t = new Date();
        _t.setYear(t.getFullYear());
        _t.setMonth(t.getMonth());
        _t.setDate(t.getDate());

        var date = _t.getDate(); //给定的日期是几号

        _t.setDate(1);
        var d = _t.getDay(); //1. 得到当前的1号是星期几。
        var fisrtWeekend = d;
        if (d == 0) {
          fisrtWeekend = 1;
          //1号就是星期天
        } else {
          fisrtWeekend = 7 - d + 1; //第一周的周未是几号
        }
        if (date <= fisrtWeekend) {
          return 1;
        } else {
          return 1 + Math.ceil((date - fisrtWeekend) / 7);
        }
      }
    },
    weekChange1(val) {
      if (val) {
        this.week1 = this.getWeekInMonth(new Date(val));
      }
    },
    weekChange2(val) {
      if (val) {
        this.week2 = this.getWeekInMonth(new Date(val));
      }
    },
    // 切换日期类型
    checkTimeType(index) {
      this.timeType = index;
      this.timeDay = "";
      this.timeWeekStart = "";
      this.timeWeekEnd = "";
      this.timeMonth = "";
      this.startTime = "";
      this.endTime = "";
      // this.busEmit()
      // 广播出去
      // Bus.$emit('compareTimeType', this.timeType)
    },
    busEmit() {
      if (this.timeType == 1) {
        if (this.timeDay) {
          let startTime = new Date(this.timeDay[0]).getTime();
          let endTime = new Date(this.timeDay[1]).getTime();
          this.startTime = this.formatDate(startTime)
            .replace("-", "")
            .replace("-", "");
          this.endTime = this.formatDate(endTime)
            .replace("-", "")
            .replace("-", "");
        } else {
          this.startTime = "";
          this.endTime = "";
        }
      } else if (this.timeType == 2) {
        if (this.timeWeekStart && this.timeWeekEnd) {
          // // 年
          // let year0 = this.timeWeekStart.getFullYear()
          // // 月
          // let month0 = this.timeWeekStart.getMonth() + 1
          // // 年
          // let year1 = this.timeWeekEnd.getFullYear()
          // // 月
          // let month1 = this.timeWeekEnd.getMonth() + 1
          // this.startTime = `${year0}${month0 < 10 ? '0'+month0 : month0}${this.week1}`
          // this.endTime = `${year1}${month1 < 10 ? '0'+month1 : month1}${this.week2}`
          let moment = require("moment");
          this.startTime = moment(this.timeWeekStart)
            .utcOffset(480)
            .format("yyyyWW");
          let year1 = this.startTime.toString().slice(0, 4);
          let week1 = Number(this.startTime.toString().slice(4));
          this.startTime = year1 + week1;

          this.endTime = moment(this.timeWeekEnd)
            .utcOffset(480)
            .format("yyyyWW");
          let year2 = this.endTime.toString().slice(0, 4);
          let week2 = Number(this.endTime.toString().slice(4));
          this.endTime = year2 + week2;
        } else {
          this.startTime = "";
          this.endTime = "";
        }
      } else if (this.timeType == 3) {
        if (this.timeMonth) {
          // 年
          let year0 = this.timeMonth[0].getFullYear();
          // 月
          let month0 = this.timeMonth[0].getMonth() + 1;
          // 年
          let year1 = this.timeMonth[1].getFullYear();
          // 月
          let month1 = this.timeMonth[1].getMonth() + 1;
          this.startTime = `${year0}${month0 < 10 ? "0" + month0 : month0}`;
          this.endTime = `${year1}${month1 < 10 ? "0" + month1 : month1}`;
        } else {
          this.startTime = "";
          this.endTime = "";
        }
      }
      let params = {
        dateType: this.timeType,
        // deptIds: this.select_data_tree.length > 0 ? JSON.stringify(this.select_data_tree) : '',
        // productLineIds: this.selectClass.length > 0 ? JSON.stringify(this.selectClass) : '',
        startTime: this.startTime,
        endTime: this.endTime,
      };
      console.log("params", params);
      // Bus.$emit("compareTimeType", params);
    },
    // 查询数据
    async querySelect() {
      this.busEmit();
      console.log("开始时间", this.startTime);
      console.log("结束时间", this.endTime);
      this.isLoading = true;
      this.$store.dispatch("app/setLoading", true);
      // 数据请求
      let params = {
        dateType: this.timeType,
        // deptIds: this.select_data_tree.length > 0 ? JSON.stringify(this.select_data_tree) : '',
        // productLineIds: this.selectClass.length > 0 ? JSON.stringify(this.selectClass) : '',
        startTime: this.startTime,
        endTime: this.endTime,
      };
      this.axios
        .post("COMMONINCOME", params)
        .then((result) => {
          console.log("查询返回", result);
          if(result.retCode=="200"){
            let copareData = {
            selectData: params,
            result: result.data,
          };

          Bus.$emit("copareData", copareData);
          }else{
            this.$message.error(result.msg);
          }
         
          // this.$store.dispatch('data/setTotalData', result.data)
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
    formatDate(number) {
      var date = new Date(number);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return YY + MM + DD;
    },
  }
};
</script>

<style>
@media (max-width: 1440px) {
  /* 小于1440 */
  .week-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .week-input2 {
    margin-right: 0 !important;
    margin-top: 10px;
  }
  .text-btn2 {
    margin-left: 8px !important;
  }
  .text-btn3 {
    margin-left: 8px !important;
    margin-right: 0px !important;
  }
  .select-day {
    margin-left: 12px !important;
  }
  .class-list {
    margin-left: 8px !important;
  }
}
</style>

<style scoped lang="scss">
.select-card {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  margin-top: 24px;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    .question {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
    .question:hover {
      cursor: pointer;
    }
  }
  .select-card-bottom {
    padding: 8px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .select-card-bottom-left {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .class-list {
        margin-left: 24px;
        width: 200px;
      }
    }
    .select-card-bottom-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>

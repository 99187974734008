<template>
  <div class="select-card-child">
    <div
      class="select-card-bottom"
      v-watermark="{
        text: text,
        textColor: 'rgba(180, 180, 180, 0.3)',
      }"
    >
      <div id="china_map_box">
        <div id="china_map" :style="{ height: '400px' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import china from "@/assets/json/china.json";
import colors from "@/utils/color";
echarts.registerMap("china", china);

export default {
  name: "index",
  props: ["tissueComparison"],
  data() {
    return {
      doctors: [],
      doctorIds: [],
      yData: [],
      cascaderProps: {
        multiple: true,
        label: "regionName",
        value: "regionCode",
        lazy: true,
        checkStrictly: true,
        lazyLoad: this.lazyLoad,
      },
      lineColor: "#00B585",
      itemStyle: {
        normal: {
          color: "",
        },
      },
      text: "",
      colorRange: [],
    };
  },
  created() {
    if (sessionStorage.getItem("userInfo")) {
      this.text = JSON.parse(sessionStorage.getItem("userInfo")).name;
    }
  },
  mounted() {
    this.$nextTick(() => {
      // if(this.tissueComparison.deptNameList.length>0){
      this.init();
      // }
    });
  },
  methods: {
    group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    },
    async init() {
      let deptNameList = this.tissueComparison.deptNameList;
      let totalAmountList = this.tissueComparison.totalAmountList;
      let percentageList = this.tissueComparison.percentageList;
      let totalAmountList_sort = JSON.parse(
        JSON.stringify(totalAmountList)
      ).sort();

      let max = Math.max(...totalAmountList_sort);
      let min = Math.min(...totalAmountList_sort);

      let avg = Math.ceil(max / 6);
      // console.log(min,max,avg)
      if (deptNameList.length > 0) {
        this.colorRange = [
          {
            start: Math.ceil(min + avg * 4),
            label: `> ${Math.ceil(min + avg * 4)}万`,
            color: "#155190",
          },
          {
            start: Math.ceil(min + avg * 3),
            end: Math.ceil(min + avg * 4),
            label: `${Math.ceil(min + avg * 3)} - ${Math.ceil(
              min + avg * 4
            )}万`,
            color: "#1367BC",
          },
          {
            start: Math.ceil(min + avg * 2),
            end: Math.ceil(min + avg * 3),
            label: `${Math.ceil(min + avg * 2)} - ${Math.ceil(
              min + avg * 3
            )}万`,
            color: "#157CE3",
          },
          {
            start: Math.ceil(min + avg * 1),
            end: Math.ceil(min + avg * 2),
            label: `${Math.ceil(min + avg * 1)} - ${Math.ceil(
              min + avg * 2
            )}万`,
            color: "#5CA5EB",
          },
          {
            start: min,
            end: Math.ceil(min + avg * 1),
            label: `${Math.ceil(min)} - ${Math.ceil(min + avg * 1)}万`,
            color: "#C0DFFD",
          },
          {
            start: 0,
            end: min,
            label: `0 - ${Math.ceil(min)}万`,
            color: "#D9D9D9",
          },
        ];
      } else {
        this.colorRange = [{ start: 0, label: `0万`, color: "#D9D9D9" }];
      }

      this.yData = [];
      deptNameList.forEach((element, index) => {
        let temp = {
          itemStyle: {
            normal: {
              color: "#D8E9FD",
            },
          },
        };

        // temp.name = element;
        let sIndex = element.indexOf("省"); //带省
        let zIndex = element.indexOf("自治区");
        let ssIndex = element.indexOf("市");
        // console.log(zIndex,element)
        if (sIndex != -1) {
          temp.name = element.slice(0, sIndex);
        } else if (zIndex != -1) {
          // console.log(zIndex)
          if (element == "内蒙古自治区") {
            temp.name = "内蒙古";
          } else if (element == "新疆维吾尔自治区") {
            temp.name = "新疆";
          } else if (element == "西藏自治区") {
            temp.name = "西藏";
          } else {
            temp.name = element.slice(0, 2);
          }
        } else if (ssIndex != -1) {
          temp.name = element.slice(0, ssIndex);
        }
        if (element == "香港特别行政区") {
          temp.name = "香港";
        }
        if (element == "澳门特别行政区") {
          temp.name = "澳门";
        }
        // console.log(temp.name)

        temp.value = totalAmountList[index];
        temp.perf = percentageList[index];
        temp.color = this.lineColor[index];
        // console.log(temp.color);
        if (!temp.value) {
          temp.itemStyle.normal.color = "#D9D9D9";
        }
        // console.log(temp);

        this.yData.unshift(temp);
      });
      this.setMapData();
    },
    lazyLoad(node, resolve) {
      this.getData(node, resolve);
    },
    async getData(node, resolve) {
      let that = this;
      let { level, value } = node;
      let params = {
        levelType: parseInt(level + 1),
        regionCode: value,
        type: 1,
      };
      if (!node.data) {
        let test = [];
        await this.axios
          .post("HospitalOrDoctor", params)
          .then((result) => {
            test = result.data;
          })
          .catch(() => {
            this.isLoading = false;
            this.$store.dispatch("app/setLoading", false);
          });
        setTimeout(() => {
          const nodes = Array.from(test).map((item) => ({
            value: item.regionCode,
            label: `${item.regionName}`,
            leaf: level >= 4,
          }));
          resolve([...nodes]);
          // this.$forceUpdate()
        }, 500);
      } else {
        let test = [];
        await this.axios
          .post("HospitalOrDoctor", params)
          .then((result) => {
            test = result.data;
            setTimeout(() => {
              const nodes = Array.from(test).map((item) => {
                return {
                  regionCode: item.regionCode,
                  regionName: `${item.regionName}`,
                  leaf: level >= 4,
                  // level
                };
              });
              resolve(nodes);
            }, 500);
          })
          .catch(() => {
            this.isLoading = false;
            this.$store.dispatch("app/setLoading", false);
          });
      }
    },
    // 查询数据
    querySelect() {
      //type:1 组织架构 2:产品线
      this.isLoading = true;
      this.$store.dispatch("app/setLoading", true);
      let params = {},
        url = "PRODUCTLINEINCOME";
      params = {
        dateType: this.dateParam.dateType,
        startTime: this.dateParam.startTime,
        endTime: this.dateParam.endTime,
        doctorIds: this.doctorIds,
      };

      // 数据请求
      this.axios
        .post(url, params)
        .then((result) => {
          console.log("对比分析产品-查询返回", result);
          this.tissueComparison = result.data;
          this.random = Math.random();
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
          // this.$store.dispatch("data/setIncomeProduct", result.data);
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
    // 地图
    setMapData() {
      // 初始化echarts实例 #D8E9FD
      let that = this;
      this.chinachart = echarts.init(document.getElementById("china_map"));
      this.chinachart.clear();
      // 进行相关配置
      this.chartOption = {
        tooltip: {
          padding: 12,
          extraCssText: "box-shadow: 0px 2px 12px 0px rgba(64, 98, 117, 0.1);",
          // 鼠标移到图里面的浮动提示框
          // formatter详细配置： https://echarts.baidu.com/option.html#tooltip.formatter
          formatter(params) {
            // params.data 就是series配置项中的data数据遍历
            let localValue, perf;
            if (params.data) {
              localValue = params.data.value;
              perf = params.data.perf;
              //   downloadSpeep = params.data.downloadSpeep;
              //   usability = params.data.usability;
              //   point = params.data.point;
            } else {
              // 为了防止没有定义数据的时候报错写的
              localValue = 0;
              perf = 0;
            }
            let htmlStr = `
          <div style='font-size:12px;'> <span style="display:inline-block;width:10px;height:10px;background:${that.lineColor};
border-radius: 2px;margin-right:4px;"></span>${params.name}</div>
          <p style='text-align:left;margin-top:4px;'>
              实际销售额：<span style="display:inline-block;margin-left:4px;text-align:right;float:right;">${localValue}万</span>
          </p>
          <p style='text-align:left;margin-top:4px;width:100%'>
              占比：<span style="display:inline-block;margin-left:4px;text-align:right;float:right;">${perf}%</span>
          </p>
        `;
            return htmlStr;
          },
          backgroundColor: "#ffffff", //提示标签背景颜色
          textStyle: { color: "#262626", fontSize: 12 }, //提示标签字体颜色
        },
        dataRange: {
          // show:false,
          calculable: false,
          //左下角的颜色块。start：值域开始值；end：值域结束值；label：图例名称；color：自定义颜色值
          x: "left",
          y: "bottom",
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 12,
          // textGap:12,
          splitList: this.colorRange,
        },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "60",
          containLabel: true,
        },
        series: [
          {
            name: "地图", // 浮动框的标题（上面的formatter自定义了提示框数据，所以这里可不写）
            type: "map",
            geoIndex: 0,
            map: "china",
            zoom: 1.2,
            label: {
              show: false,
              color: "white",
              fontSize: 12,
            },
            itemStyle: {
              normal: {
                borderWidth: 1, //边际线大小
                borderColor: "#FFFFFF", //边界线颜色
                areaColor: "#D9D9D9", //默认区域颜色
              },
              emphasis: {
                borderWidth: 2,
                show: true,
                shadowColor: "rgba(0, 0, 0, 0.5)",
                areaColor: "#00B585", //鼠标滑过区域颜色
                label: {
                  show: true,
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            },
            // 这是需要配置地图上的某个地区的数据，根据后台的返回的数据进行拼接（下面是我定义的假数据）
            data: this.yData,
          },
        ],
        // dataList: this.yData,
      };
      // 使用刚指定的配置项和数据显示地图数据
      this.chinachart.setOption(this.chartOption);
    },
  },
};
</script>
<style scoped lang="scss">
.select-card-child {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  margin-top: 24px;
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .select-card-bottom {
    // padding: 8px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 36px;
  }
  .main {
    width: 100%;
    min-height: 500px;
  }
}
</style>
<style scoped>
#china_map_box {
  height: 100%;
  width: 100%;
  position: relative;
}
#china_map_box #china_map {
  height: 100%;
}
#china_map_box .china_map_logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 45px;
}
</style>
<style>
#china_map .tooltip_style {
  line-height: 1.7;
  overflow: hidden;
}
#china_map .tooltip_left {
  float: left;
}
#china_map .tooltip_right {
  float: right;
}
</style>

<template>
  <div class="box">
    <div class="card-box" id="scroll" ref="wrapper">
      <div
        v-for="(item, index) in cardList"
        :key="index"
        class="card-box-item"
        :style="{
          backgroundImage: 'url(' + item.lineColor + ')',
          color: index == 0 ? '#fff' : '',
          backgroundSize:'100% 100%'
        }"
      >
        <div class="card-box-item-title-box">
          <div
            :class="index == 0 ? 'card-box-item-title0' : 'card-box-item-title'"
          >
            {{ item.title }}
          </div>
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.remarks"
            placement="bottom"
          >
            <img src="@/assets/qs.png" />
          </el-tooltip>
        </div>
        <div class="card-box-item-num-box">
          <div
            :class="index == 0 ? 'card-box-item-num0' : 'card-box-item-num'"
            v-if="index != 1"
          >
            {{
              item.num
                ? String(item.num).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : "--"
            }}
          </div>
          <div
            :class="index == 0 ? 'card-box-item-num0' : 'card-box-item-num'"
            v-if="index == 1"
          >
            {{
              item.num 
                ? String(item.num).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : "--"
            }}
          </div>
        </div>
        <div class="card-box-item-bottom" v-if="item.with || item.ringIsRing">
          <div class="card-box-item-bottom-item">
            <div
              :class="
                index == 0
                  ? 'card-box-item-bottom-item-title0'
                  : 'card-box-item-bottom-item-title'
              "
            >
              同比
            </div>
            <div v-if="item.withIsAdd" class="rise">
              <img class="arrow" src="@/assets/rise.png" />
              <div class="card-box-item-bottom-item-num1">{{ item.with }}%</div>
            </div>
            <div v-else class="drop">
              <img class="arrow" src="@/assets/drop.png" />
              <div class="card-box-item-bottom-item-num2">{{ item.with }}%</div>
            </div>
          </div>
          <div class="card-box-item-bottom-item" v-if="item.ring">
            <div
              :class="
                index == 0
                  ? 'card-box-item-bottom-item-title0'
                  : 'card-box-item-bottom-item-title'
              "
            >
              环比
            </div>
            <div v-if="item.ringIsRing" class="rise">
              <img class="arrow" src="@/assets/rise.png" />
              <div class="card-box-item-bottom-item-num1">{{ item.ring }}%</div>
            </div>
            <div v-else class="drop">
              <img class="arrow" src="@/assets/drop.png" />
              <div class="card-box-item-bottom-item-num2">{{ item.ring }}%</div>
            </div>
          </div>
        </div>
        <div class="card-box-item-bottom" v-if="index == 2">
          <div class="card-box-item-bottom-item">
            <div class="quekou">
              销售缺口
              <span class="quekou_span">{{
                item.saleGap ? item.saleGap : "--"
              }}</span
              >万
            </div>
          </div>
        </div>
        <div class="card-box-item-bottom" v-if="item.historySalesTopDate">
          <div class="card-box-item-bottom-item">
            <div class="date">{{ item.historySalesTopDate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/utils/bus";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      scrollToX: 0,
      isShowRight: true,
      isShowLeft: false,
      timeType: 1, // 时间类型
      xData: {},
    };
  },
  computed: {
    ...mapGetters(["cardList"]),
  },

  created() {
    //  console.log( this.xData.id)
    //  console.log(this.cardList)
    //  this.cardList.forEach(element => {
    //    if(element.historySalesTopDate){
    //      let str = element.historySalesTopDate.toString().split("")
    //     //  if(str.length==8){
    //     //  }else if(str.length==){
    //     //  }
    //    }
    //  });
  },
  mounted() {
    this.xData = this.cardList[0];
    console.log(this.xData);
    console.log(this.xData["asis"]);
    Bus.$on("overviewTimeType", (result) => {
      this.timeType = result;
      if (result != 3) {
        this.cardList[2]["isSelect"] = false;
      }
    });
  },
  methods: {},
};
</script>


<style scoped>
.box {
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;
}
.quekou {
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #262626;
  line-height: 22px;
}
.date {
  height: 22px;
  font-size: 14px;
  font-family: MiSans-Regular, MiSans;
  font-weight: 400;
  color: #0e7744;
  line-height: 22px;
}
.quekou_span {
  min-width: 18px;
  margin: 0 6px;
  height: 22px;
  font-size: 14px;
  font-family: MiSans-Regular;
  font-weight: 400;
  color: #ff7c00;
  line-height: 22px;
}
.svg-icon {
  width: 10px;
  height: 10px;
}
.card-box {
  width: 100%;
  display: flex;
}
.card-box:hover {
  cursor: pointer;
}
.card-box::-webkit-scrollbar {
  display: none;
}
.card-box-item {
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  padding: 24px;
  box-sizing: border-box;
  margin-right: 12px;
  white-space: nowrap;
  flex-basis: 274px;
  min-height: 156px;
  flex-shrink: 0;
  flex: 1;
}
.card-box-item:last-child {
  margin-right: 0;
}
.card-box-item-icon {
  width: 28px;
  height: 28px;
}
.card-box-item-icon img {
  width: 100%;
  height: 100%;
}
.card-box-item-title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card-box-item-title {
  font-size: 14px;
  color: #262626;
  margin-top: 4px;
}
.card-box-item-title0 {
  font-size: 14px;
  color: #ffffff;
  margin-top: 4px;
 
}
.card-box-item-title-box img {
  width: 16px;
  height: 16px;
  margin-left: 3px;
  margin-bottom: -4px;
}
.card-box-item-num-box {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 24px;
  font-family: MiSans-Demibold;
}
.card-box-item-num {
  font-weight: bold;
  color: #262626;
  font-size: 32px;
  font-family: MiSans-Demibold;
}
.card-box-item-num0 {
  font-weight: bold;
  color: #ffffff;
  font-size: 32px;
  font-family: MiSans-Demibold;
}
.card-box-item-unit {
  color: #00000085;
  font-size: 16;
  margin-left: 5px;
  margin-bottom: 5px;
}
.card-box-item-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.card-box-item-bottom-item {
  display: flex;
  flex-direction: row;
  height: 20px;
}
.rise {
  min-width: 47px;
  height: 100%;
  background: #ffffff;
  border-radius: 4px;
  opacity: 0.9;
  display: flex;
  line-height: 20px;
  color: #ff5322;
  padding:0 4px;
  box-sizing: border-box;
  font-family: MiSans-Regular;
  
}
.drop {
  min-width: 47px;
  height: 100%;
  background: #ffffff;
  border-radius: 4px;
  opacity: 0.9;
  display: flex;
  margin-left: 2px;
  color: #00a4ff;
  padding:0 4px;
  box-sizing: border-box;
  font-family: MiSans-Regular;
}

.arrow {
  display: inline;
  width: 10px;
  height: 10px;
  margin-left: 2px;
  margin-top: 5px;
  margin-right: 4px;
}
.card-box-item-bottom-item-title {
  font-size: 12px;
  color: #595959;
  margin-right: 4px;
}
.card-box-item-bottom-item-title0 {
  font-size: 12px;
  color: #ffffff;
  margin-right: 4px;
  line-height: 20px;
  /* display: table-cell;
  vertical-align: bottom; */
}
.card-box-item-bottom-item-num1 {
  font-size: 12px;
  color: #ff5322;
  
}
.card-box-item-bottom-item-num2 {
  font-size: 12px;
  color: #00a1ff;
}
.select-box {
  position: absolute;
  top: 0;
  right: 0;
}
.select-box img {
  width: 18px;
  height: 18px;
}
@media screen and (min-width:2000px) {
    .card-box-item {
        border-radius: 15px;
    }
}
</style>
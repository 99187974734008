<template>
  <div class="select-card">
    <div class="select-card-top"></div>
    <div
      class="select-card-bottom"
      v-watermark="{ text: text, textColor: 'rgba(180, 180, 180, 0.3)' }"
    >
      <div
        id="myChart"
        :style="{ width: '100%', height: '450px' }"
        :key="random"
      ></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { mapGetters } from "vuex";
export default {
  props: ["random"],
  data() {
    return {
      legendData: [],
      asis: [],
      saleMoneyList: [], //实际销售额
      salesAvgList: [], //平均销售额
      salesRadioList: [], //销售额达成率
      salesTargetList: [], //目标销售额
      yData: [],
      // random: 0,
      text: "",
      total: [],
    };
  },
  computed: {
    ...mapGetters(["cardList"]),
  },
  watch: {
    cardList: {
      handler(newName, oldName) {
        this.yData = [];
        this.legendData = [];
        let lineData = newName[0].lineData;
        console.log(newName[0].lineData, "测试");
        this.asis = lineData.asis;
        if (lineData.asis.length > 0) {
          this.saleMoneyList = lineData.saleMoneyList; //实际销售额
          this.salesAvgList = lineData.salesAvgList; //平均销售额
          this.salesRadioList = lineData.salesRadioList; //销售额达成率
          this.salesTargetList = lineData.salesTargetList; //目标销售额
          this.total = [
            ...this.saleMoneyList,
            ...this.salesAvgList,
            ...this.salesTargetList,
          ];
          if (this.saleMoneyList && this.saleMoneyList.length > 0) {
            this.legendData.push("实际销售额");
          }
          if (this.salesTargetList && this.salesTargetList.length > 0) {
            this.legendData.push("目标销售额");
          }
          if (this.salesAvgList && this.salesAvgList.length > 0) {
            this.legendData.push("平均销售额");
          }
          if (this.salesRadioList && this.salesRadioList.length > 0) {
            this.legendData.push("销售达成率");
          }
        }

        // console.log(this.legendData, "this.legendData");
        this.legendData.forEach((element) => {
          let temp = {
            name: element,
            type: "line",
            data: [],
            smooth: true,
          };
          if (element == "实际销售额") {
            temp.data = this.toolTipInit(this.saleMoneyList, "实际销售额");
          }
          if (element == "目标销售额") {
            temp.data = this.toolTipInit(this.salesTargetList, "目标销售额");
          }
          if (element == "平均销售额") {
            temp.data = this.toolTipInit(this.salesAvgList, "平均销售额");
          }
          if (element == "销售达成率") {
            temp.data = this.toolTipInit(this.salesRadioList, "销售达成率");
            temp.yAxisIndex = 1;
          }
          this.yData.unshift(temp);
        });
        console.log(this.yData);
        // this.$forceUpdate();
        this.$nextTick(() => {
          if (newName[0].lineData) {
            console.log("hhahahahhaha");
            this.drawLine();
          }
        });
      },
      deep: true,
    },
  },
  created() {
    if (sessionStorage.getItem("userInfo")) {
      this.text = JSON.parse(sessionStorage.getItem("userInfo")).name;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.cardList[0].lineData) {
        this.drawLine();
      }
    });
  },
  methods: {
    toolTipInit(val, label) {
      let data = [];
      val.forEach((i) => {
        let i_temp = {};
        i_temp.title = label;
        i_temp.value = i;
        data.push(i_temp);
      });
      return data;
    },
    drawLine() {
      let that = this;
      // 基于准备好的dom，初始化echarts实例

      let myChart = echarts.init(document.getElementById("myChart"));
      console.log("999999");
      myChart.clear();

      // 绘制图表
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            // shadowStyle: {
            //   color: "rgba(64, 98, 117, 0.1)",
            // },
          },
          extraCssText: "box-shadow: 0px 2px 12px 0px rgba(64, 98, 117, 0.1);",
          textStyle: {
            color: "#262626", //设置文字颜色
            fontSize: 12,
          },
          padding: 12,
          backgroundColor: "#FFFFFF", //设置背景颜色
          borderColor: "#FFFFFF", //设置边框颜色
          formatter(params) {
            // console.log(params); //我们可以打印该值
            let html = "";
            for (let i in params) {
              html += `<span style="display:inline-block;width:10px;height:10px;background:${
                params[i].color
              };
border-radius: 2px;margin-right:4px;"></span>${
                params[i].data.title
              }:<p style="min-width:20px;text-align:right;display:inline-block;float:right;margin-left:4px;">${Number(
                params[i].data.value.toString().match(/^\d+(?:\.\d{0,2})?/)
              )}<span style="margin-left:4px;">${
                params[i].data.title.indexOf("率") == -1 ? "万" : "%"
              }</span></p><br/>`;
            }
            // 返回值就是html代码可以使用标签
            return html;
          },
        },
        color: ["#5871c0", "#9ec97e", "#84dfdb", "#de6d6a", "#84dfbd"],
        legend: {
          left: "left",
          itemWidth: 12,
          itemHeight: 12,
          type: "scroll",
          orient: "horizontal",
          itemGap: 24,
          icon: "roundRect",
          data: that.legendData,
        },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "24",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: that.asis,
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#F0F0F0",
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#8C8C8C",
            },
          },

          axisTick: {
            show: false, //y轴坐标点消失
          },
          axisLine: {
            show: false, //y轴线消失
          },
        },
        yAxis: [
          {
            type: "value",
            // min:0,
            splitNumber: 5,
            interval: Math.ceil(Math.ceil(Math.max(...this.total)) / 5),
            max: Math.ceil(Math.ceil(Math.max(...this.total)) / 5) * 5,
            axisTick: {
              show: false, //y轴坐标点消失
            },
            axisLine: {
              show: false, //y轴线消失
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#F0F0F0",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#8C8C8C",
              },
            },
          },
          {
            type: "value",
            position: "right",
            formatter: "{value}%",
            nameTextStyle: { fontSize: 12, color: "#8C8C8C", align: "center" },
            itemStyle: {
              color: "#ffd07a",
            },
            //  min:0,
            splitNumber: 5,
            interval: Math.ceil(
              Math.ceil(Math.max(...this.salesRadioList)) / 5
            ),
            max: Math.ceil(Math.ceil(Math.max(...this.salesRadioList)) / 5) * 5,
            axisTick: {
              show: false, //y轴坐标点消失
            },
            axisLine: {
              show: false, //y轴线消失
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#F0F0F0",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#8C8C8C",
              },
            },
          },
        ],
        series: that.yData,
      };
      // console.log(option, "option");

      option && myChart.setOption(option, true);
    },
  },
};
</script>

<style scoped lang="scss">
.select-card {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  margin-top: 24px;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .select-card-bottom {
    padding: 8px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .main {
    width: 100%;
    min-height: 500px;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{ref:"wrapper",staticClass:"card-box",attrs:{"id":"scroll"}},_vm._l((_vm.cardList),function(item,index){return _c('div',{key:index,staticClass:"card-box-item",style:({
        backgroundImage: 'url(' + item.lineColor + ')',
        color: index == 0 ? '#fff' : '',
        backgroundSize:'100% 100%'
      })},[_c('div',{staticClass:"card-box-item-title-box"},[_c('div',{class:index == 0 ? 'card-box-item-title0' : 'card-box-item-title'},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.remarks,"placement":"bottom"}},[_c('img',{attrs:{"src":require("@/assets/qs.png")}})])],1),_c('div',{staticClass:"card-box-item-num-box"},[(index != 1)?_c('div',{class:index == 0 ? 'card-box-item-num0' : 'card-box-item-num'},[_vm._v(" "+_vm._s(item.num ? String(item.num).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "--")+" ")]):_vm._e(),(index == 1)?_c('div',{class:index == 0 ? 'card-box-item-num0' : 'card-box-item-num'},[_vm._v(" "+_vm._s(item.num ? String(item.num).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "--")+" ")]):_vm._e()]),(item.with || item.ringIsRing)?_c('div',{staticClass:"card-box-item-bottom"},[_c('div',{staticClass:"card-box-item-bottom-item"},[_c('div',{class:index == 0
                ? 'card-box-item-bottom-item-title0'
                : 'card-box-item-bottom-item-title'},[_vm._v(" 同比 ")]),(item.withIsAdd)?_c('div',{staticClass:"rise"},[_c('img',{staticClass:"arrow",attrs:{"src":require("@/assets/rise.png")}}),_c('div',{staticClass:"card-box-item-bottom-item-num1"},[_vm._v(_vm._s(item.with)+"%")])]):_c('div',{staticClass:"drop"},[_c('img',{staticClass:"arrow",attrs:{"src":require("@/assets/drop.png")}}),_c('div',{staticClass:"card-box-item-bottom-item-num2"},[_vm._v(_vm._s(item.with)+"%")])])]),(item.ring)?_c('div',{staticClass:"card-box-item-bottom-item"},[_c('div',{class:index == 0
                ? 'card-box-item-bottom-item-title0'
                : 'card-box-item-bottom-item-title'},[_vm._v(" 环比 ")]),(item.ringIsRing)?_c('div',{staticClass:"rise"},[_c('img',{staticClass:"arrow",attrs:{"src":require("@/assets/rise.png")}}),_c('div',{staticClass:"card-box-item-bottom-item-num1"},[_vm._v(_vm._s(item.ring)+"%")])]):_c('div',{staticClass:"drop"},[_c('img',{staticClass:"arrow",attrs:{"src":require("@/assets/drop.png")}}),_c('div',{staticClass:"card-box-item-bottom-item-num2"},[_vm._v(_vm._s(item.ring)+"%")])])]):_vm._e()]):_vm._e(),(index == 2)?_c('div',{staticClass:"card-box-item-bottom"},[_c('div',{staticClass:"card-box-item-bottom-item"},[_c('div',{staticClass:"quekou"},[_vm._v(" 销售缺口 "),_c('span',{staticClass:"quekou_span"},[_vm._v(_vm._s(item.saleGap ? item.saleGap : "--"))]),_vm._v("万 ")])])]):_vm._e(),(item.historySalesTopDate)?_c('div',{staticClass:"card-box-item-bottom"},[_c('div',{staticClass:"card-box-item-bottom-item"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.historySalesTopDate))])])]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }